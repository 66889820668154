.alert {
	display: inline-block;
	text-align: center;
	@include media-breakpoint-down(lg) {
		padding: 10px; }
	@include media-breakpoint-down(sm) {
		hr {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem; } } }

.alert-container {
	display: flex;
	justify-content: space-around;
	@include media-breakpoint-down(sm) {
		display: block;
		text-align: center; } }

.alert-heading {
	@include media-breakpoint-down(sm) {
		@include rem(16); } }
