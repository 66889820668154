// footer
.footer {
	box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.1);
	position: relative;
	&-top {
		background-color: $dark;
		padding: 80px 0 80px 0;
		padding: 0;
		.navbar-bottom-item span {
			min-width: 100px; } }
	&-bottom {
		background-color: #1e1c1c;
		padding: 15px 0; }
	.copyright {
		color: #868383;
		@include rem(16);
		letter-spacing: 0.7px;
		margin-bottom: 0;
		line-height: 2;
		font-weight: 300;
		display: inline-block; } }

.mooiwurk-icon {
	@include trans;
	width: 100%;
	max-width: 20px;
	margin-right: 5px;
	margin-left: 5px;
	display: inline-block; }

.link-icon-mooiwurk-animation {
	color: #fff;
	font-weight: 300;
	display: inline-block;
	&:hover {
		color: #fff; } }
.copyright:hover .mooiwurk-icon {
	animation-name: presentation;
	animation-duration: 1.2s;
	animation-iteration-count: 1; }


.footer-logo {
	display: inline-block;
	margin-bottom: 30px;
	img {
		width: 100%;
		max-width: 200px;
		@include media-breakpoint-down(md) {
			max-width: 180px; } } }

.footer-form {
	background-color: #1e1c1c;
	padding: 60px 85px;
	min-height: 100%;
	@include media-breakpoint-down(lg) {
		padding: 50px 40px;
		max-width: 500px;
		margin: 0 auto; }
	@include media-breakpoint-down(sm) {
		max-width: 550px;
		margin-left: -15px;
		margin-right: -15px; }
	@include media-breakpoint-down(xs) {
		padding: 40px 15px; } }





// end footer
