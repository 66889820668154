/* SCALE (USE THIS) */

.home-bg-animation {
  background-color: #EEE;
  border-bottom: 2px solid #282828;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; }

.home-bg-animation--image {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  img {
    animation: move 10s ease infinite;
    // Change this to alternate to stop the loop
    -ms-animation: move 10s ease infinite;
    -webkit-animation: move 10s ease infinite;
    -moz-animation: move 10s ease infinite;
    position: absolute;
    left: -150px;
    top: -150px;
    @include media-breakpoint-down(lg) {
      left: -200px;
      top: -300px;
      width: 200%; }
    @include media-breakpoint-down(sm) {
      top: -200px; }
    @include media-breakpoint-down(xs) {
      width: 340%; }
    @include media-breakpoint-down(xs) {
      top: -300px;
      left: -500px; } } }

@-webkit-keyframes move {
  from {
    transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -o-transform: scale(0.9);
    -moz-transform: scale(0.9); }
  to {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -moz-transform: scale(1); } }



@media (max-width: 374.98px) {
  .home-bg-animation--image img {
    top: -200px;
    left: -400px; } }

