.media {
  @include media-breakpoint-down(sm) {
    display: block; } }
.media-title {
  align-items: center;
  margin-bottom: 10px;
  &-box {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0; } }
  &-img {
    width: 100%;
    max-width: 50px;
    margin-right: 25px; } }
