.navbar-bottom {
  margin-top: auto;
  margin-right: auto;
  &-list {
    margin: 0;
    padding: 0;
    font-weight: 300; }
  &-item {
    list-style: none;
    display: flex;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0; }
    span {
      color: #fff;
      margin-right: 5px;
      min-width: 85px;
      display: inline-block; } }
  &-link {
    display: inline-block;
    color: #868383;
    @include rem(16);
    @include trans;
    @include media-breakpoint-down(xs) {
      @include rem(14); } }
  span.navbar-bottom-link {
    color: #868383; } }
