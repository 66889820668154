
form {
	.btn:hover {
		cursor: pointer; } }

label {
	display: block; }

.contact-form {
	width: 100%;
	max-width: 90%;
	margin: 0 auto;
	@include media-breakpoint-down(xs) {
		max-width: 100%; } }

.success-form {
	@include rem(19);
	margin-top: 20px;
	padding: 50px 15px;
	background-color: $secondary;
	transition: all .4s ease-in-out;
	line-height: 36px;
	color: #fff;
	img {
		max-width: 200px;
		width: 100%;
		margin-bottom: 20px; } }

.form-group {
	margin-bottom: 20px; }

.form-control {
	padding: .65rem .75rem;
	background-color: #3a3939;
	border-radius: 0;
	height: calc(1.5em + 0.75rem + 14px);
	border: none;
	box-shadow: none;
	color: #838282;
	font-weight: 300; }
.form-control:focus {
	box-shadow: none;
	background-color: #3a3939;
	color: #fff;
	font-weight: 300; }


::-webkit-input-placeholder {
	text-align: center;
	color: #838282;
	font-weight: 300; }
:-moz-placeholder {
	text-align: center;
	color: #838282;
	font-weight: 300; }
::-moz-placeholder {
	text-align: center;
	color: #838282;
	font-weight: 300; }
:-ms-input-placeholder {
	text-align: center;
	color: #838282;
	font-weight: 300; }


@media screen and (max-width: 375px) {
	.success-form {
		@include rem(22); } }


// end form

















