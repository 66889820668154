// header
.header {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 5;
	&-content {
		background-color: transparent;
		color: #000;
		z-index: 6;
		position: relative;
		@include trans; } }

.mobile-nav-bg {
	background-color: rgba(0,0,0,0.6);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 998;
	display: none;
	transition: all .5s ease-in-out; }

.mobile-nav-bg.show {
	display: block; }

.phone-link {
	display: inline-block;
	@include rem(15);
	margin-left: auto;
	color: #fff;
	font-weight: 400;
	.fa {
		font-weight: 400; }
	span {
		display: inline-block; }
	@include hf {
		color: $primary;
		text-decoration: none; }
	@include media-breakpoint-down(xs) {
		margin-right: 0; }
	&:hover {
		color: #fff; } }

.mobile-btn-item {
	padding-left: 10px; }


.custom-nav {
	padding: 0;
	min-height: 98px;
	transition: all .5s ease-in-out;
	@include media-breakpoint-down(xs) {
		flex-direction: column; }
	.navbar-toggler-icon {
		background-image: none;
		width: 33px;
		height: 10px;
		position: relative;
		margin: 3px 10px 5px 0px;
		.top-line,
		.bottom-line {
			display: inline-block;
			background-color: #000;
			height: 2px;
			border-radius: 20px;
			position: absolute;
			left: 0;
			right: 0;
			@include trans; }
		.top-line {
			top: 0; }
		.bottom-line {
			bottom: 0;
			left: 10px; } }
	.navbar-toggler {
		margin-left: 0;
		z-index: 999;
		border: none;
		margin-right: auto;
		color: #000;
		background-color: #fff;
		border-radius: 0;
		padding: 40px 11px;
		margin-left: -15px;
		box-shadow: none;
		border: none;
		cursor: pointer;
		@include rem(12);
		transition: all .5s ease-in-out;
		position: absolute;
		left: 0;
		top: 0;
		@include media-breakpoint-down(xs) {
			transition: all .54s ease-in-out; } }
	.navbar-toggler.active {
		margin-left: 335px;
		@include media-breakpoint-down(xs) {
			margin-left: 255px;
			.nav-name {
				@include rem(0); } }
		.navbar-toggler-icon {
			margin-right: 0;
			.top-line {
				top: 50%;
				margin-top: -1px;
				transform: rotate(-45deg);
				background-color: #000; }
			.bottom-line {
				left: 0;
				bottom: auto;
				top: 50%;
				margin-top: -1px;
				transform: rotate(45deg);
				background-color: #000; } } }

	.navbar-collapse {
		z-index: 999; }

	.navbar-nav {
		.nav-link {
			@include rem(20);
			font-weight: 300;
			padding-right: 0px;
			padding-left: 0px;
			padding-top: 10px;
			padding-bottom: 10px;
			margin-right: 25px;
			margin-left: 25px;
			@include trans-2;
			position: relative;
			&::before {
				content: "";
				display: inline-block;
				width: 100%;
				height: 2px;
				background-color: $danger;
				position: absolute;
				bottom: 15px;
				left: 0;
				opacity: 0;
				@include trans-2; } } } }


.navbar-brand {
	padding: 0;
	margin-bottom: 30px;
	margin-right: auto;
	img {
		max-width: 220px;
		width: 100%; }
	@include media-breakpoint-down(xs) {
		margin-bottom: 20px; } }

.nav-name {
	text-transform: uppercase;
	@include rem(12); }

.navbar-toggler-icon {
	margin-top: -6px; }

.nav-phone-link {
	display: none;
	a {
		color: $danger;
		font-weight: 700;
		@include rem(16);
		letter-spacing: 0.4px;
		@include hf {
			color: $danger;
			text-decoration: none; } }
	.fa {
		margin-right: 3px;
		@include rem(17);
		font-weight: 600; } }

.header-phone {
	display: inline-block;
	color: $danger;
	font-weight: 700;
	@include rem(16);
	letter-spacing: 0.4px;
	@include hf {
		color: $danger;
		text-decoration: none; }
	.fa {
		margin-right: 3px;
		@include rem(17);
		font-weight: 600; } }

.header-content.smaller-menu {
	background-color: #fff;
	position: fixed;
	left: 0;
	right: 0;
	top: 0px;
	@include trans;
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
	.navbar-brand {
		margin-right: auto;
		margin-left: 0; }
	.navbar-toggler {
		padding: 14px;
		@include media-breakpoint-down(xs) {
			padding: 17px 15px; }
		&.active {
			@include media-breakpoint-down(xs) {
				padding: 17px 9px; } } }
	.navbar-nav {
		padding: 0;
		background-color: transparent; }
	.phone-link {
		color: $dark; }
	.custom-nav {
		min-height: 46px;
		.nav-link {
			margin-left: 0;
			padding-left: 0;
			@include hfa {
				background-color: inherit;
				color: $danger; }
			&::before {
				display: none; } } } }






.header-content {
	.container {
		width: 100%;
		max-width: 100%; } }

.header {
	.navbar-nav {
		background-color: transparent;
		margin-bottom: 15px;
		margin-right: auto; } }

.custom-nav {
	.navbar-collapse {
		background-color: rgba(0,0,0,0.8);
		position: fixed;
		left: 0;
		top: 0;
		height: 100vh;
		width: 350px;
		padding-top: 25px;
		padding-bottom: 50px;
		padding-left: 15px;
		transition: all .5s ease-in-out;
		z-index: 1003;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-down(xs) {
			width: 270px;
			padding-bottom: 30px; } }

	.mobile-nav {
		left: -360px;
		opacity: 0;
		.mobile-btn-item {
			padding-left: 0; } }

	.mobile-nav.show {
		left: 0;
		opacity: 1;
		.mobile-btn-item {
			padding-left: 0; } }

	.navbar-nav {
		.nav-item {
			margin-bottom: 10px;
			@include media-breakpoint-down(xs) {
				margin-bottom: 0; } }
		.nav-link {
			color: #fff;
			margin: 0; } } }



.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	color: $primary; }

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
	color: $primary; }
