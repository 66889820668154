.service-card {
  width: 100%;
  max-width: 360px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 12px;
  margin-bottom: 45px;
  display: inline-block;
  text-align: left;
  @include media-breakpoint-down(lg) {
    max-width: 280px; }
  @include media-breakpoint-down(sm) {
    max-width: 240px;
    margin: 7px;
    margin-bottom: 30px; }
  @include media-breakpoint-down(xs) {
    margin: 3px;
    margin-bottom: 20px;
    max-width: 200px; }
  &-container {
    margin-left: -15px;
    margin-right: -15px;
    text-align: center; }
  &-header {
    position: relative;
    z-index: 2;
    overflow: hidden;
    &--img {
      @include media-breakpoint-down(lg) {
        height: 200px; }
      img {
        width: 100%;
        max-width: 100%;
        @include media-breakpoint-down(lg) {
          display: block;
          height: 100%;
          object-fit: cover; } } }
    &--text-container {
      padding: 15px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($secondary, 0.7);
      transform: scale(2);
      @include trans;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        position: relative;
        opacity: 1;
        transform: scale(1);
        z-index: 2;
        background-color: rgba(44, 64, 118, 1);
        min-height: 160px; } }
    &--text {
      color: #fff;
      text-align: center;
      margin: 0;
      font-weight: 300;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      padding: 15px;
      line-height: 26px;
      @include media-breakpoint-down(sm) {
        line-height: 20px; } } }
  &-bottom {
    position: relative;
    padding-top: 5px;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      padding-top: 2px; }
    &--number {
      font-weight: 900;
      @include rem(100);
      color: #e4e6ea;
      line-height: 80px;
      position: relative;
      z-index: 2; }
    &--title {
      z-index: 3;
      font-weight: 900;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      line-height: 1;
      @include media-breakpoint-down(sm) {
        left: 15px; } } }
  &:hover .service-card-header--text-container {
    transform: scale(1);
    opacity: 1;
    z-index: 3; } }




@media (max-width: 424.98px) {
  .service-card {
    max-width: 100%;
    margin: 0;
    margin-bottom: 30px; }
  .service-card-header--text {
    position: static;
    transform: translateY(0);
    padding: 0; }
  .service-card-header--text-container {
    min-height: auto; }
  .service-card-bottom--title {
    left: 35px; } }
