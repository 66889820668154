

.price-section {
	background-color: transparent;
	margin-top: -150px;
	padding: 0;
	text-align: center; }


.price-card {
	display: block;
	display: inline-block;
	max-width: 270px;
	min-width: 250px;
	width: 100%;
	text-align: center;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 30px;
	box-shadow: 0px 0px 10px #dedede;
	border-radius: 20px;
	position: relative;
	z-index: 2;
	@include trans-2;
	&-top {
		padding: 30px 15px;
		display: block;
		background-color: #fff;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		min-height: 210px;
		@include media-breakpoint-down(lg) {
			padding: 20px 10px; } }

	&-bottom {
		padding: 20px;
		display: block;
		color: #fff;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px; }
	&-title {
		color: $danger;
		font-weight: 300;
		font-size: 28px; }
	&-text {
		font-weight: 300; }
	&-img {
		width: 100%;
		margin-bottom: 20px;
		max-width: 200px; }
	&:focus,
	&:hover {
		box-shadow: 0px 0px 10px #000;
		text-decoration: none;
		transform: scale(1.02); }

	.speed-equivalent {
		@include rem(30);
		font-weight: 700; }

	.card-badge {
		display: inline-block;
		position: absolute;
		left: -12px;
		top: 5px;
		z-index: 3;
		@include rem(13);
		color: #fff;
		background-color: #90b82d;
		padding: 5px;
		text-shadow: 0px 0px 1px #6b6b6b;
		border-top-left-radius: 5px;
		border-bottom-right-radius: 3px;
		&::before {
			content: "";
			display: inline-block;
			width: 0;
			height: 0;
			border-top: 10px solid #7fa030;
			border-left: 12px solid transparent;
			position: absolute;
			bottom: -10px;
			left: 0;
			z-index: 2; }
		&::after {
			content: "";
			display: inline-block;
			width: 0;
			height: 0;
			border-top: 29px solid #8fb82d;
			border-right: 19px solid transparent;
			position: absolute;
			right: -18px;
			top: 0; } } }


.speed-load {
	display: inline-block;
	width: 47%; }


.check-list {
	text-align: left;
	font-size: 90%;
	list-style: none;
	margin: 0; }



@media screen and (max-width: 1199px) {
	.price-card {
		max-width: 265px;
		margin-left: 15px;
		margin-right: 15px; } }




@media screen and (max-width: 767px) {
	.price-section {
		margin-top: -90px; }

	.price-card .card-badge::after {
		border-top: 27px solid #8fb82d; } }



