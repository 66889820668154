
// custom variables and mixins
@import "variables";
@import "mixins";

// bootstrap
@import "bootstrap/scss/bootstrap.scss";

// custom global
@import "global";

// custom components
@import "components/alert.sass";
@import "components/animation.sass";
@import "components/buttons.sass";
@import "components/call-action-section.sass";
@import "components/footer.sass";
@import "components/form.sass";
@import "components/header.sass";
@import "components/home-animation.sass";
@import "components/home-section.sass";
@import "components/media-card.sass";
@import "components/media-title.sass";
@import "components/navbar-bottom.sass";
@import "components/prise-section.sass";
@import "components/section-services.sass";
@import "components/service-card.sass";
@import "components/services-call-action.sass";
@import "components/title-wordbg.sass";
@import "components/typography.sass";


