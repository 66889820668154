
// MIXINS

// BASE FONT SIZE
$fontSizeBase: 16;

@mixin rem($fontSize) {
	/*#{$fontSize}px*/
	font-size: ($fontSize/$fontSizeBase) + rem; }


@mixin fw($value) {
	.fw#{$value} {
		font-weight: $value; } }

// rounded corners
@mixin r($radius) {
	border-radius: $radius+px; }

// rounded corners on top
@mixin rt($radius) {
	border-top-right-radius: $radius+px;
	border-top-left-radius: $radius+px; }

// rounded corners on bottom
@mixin rb($radius) {
	border-bottom-right-radius: $radius+px;
	border-bottom-left-radius: $radius+px; }

// HOVER AND FOCUS STATE
@mixin hf {
	&:hover, &:focus {
		@content; } }

// Hover
@mixin h {
	&:hover {
		@content; } }

// Focus
@mixin fc {
	&:focus {
		@content; } }



// color and background classes
@mixin cbg($class, $color) {
	.color-#{$class} {
		color: $color !important; }

	.bg-#{$class} {
		background-color: $color !important; }

	a.color-#{$class} {
		@include hf {
			color: $color !important; } } }


// HOVER, FOCUS, ACTIVE (FOUNDATION)
@mixin hfa {
	& > a:hover,
	& > a:focus,
	&.is-active > a {
		@content; } }

@mixin in {
	display: inline-block;
	vertical-align: top; }

@mixin md {
	display: inline-block;
	vertical-align: middle; }

@mixin ts($transition) {
	-webkit-transition: $transition;
	transition: $transition; }

@mixin ct {
	content: '';
	display: block; }

@mixin ln($colors) {
	background: -webkit-linear-gradient($colors);
	background: linear-gradient($colors); }

@mixin lnl($colors) {
	background: -webkit-linear-gradient(left, $colors);
	background: linear-gradient(to right, $colors); }

@mixin lnr($colors) {
	background: -webkit-linear-gradient(right, $colors);
	background: linear-gradient(to left, $colors); }

@mixin tf($value) {
	-webkit-transform: $value;
	-ms-transform: $value;
	transform: $value; }

@mixin trans {
	transition: all .3s ease-in-out; }

@mixin trans-2 {
	transition: all .15s ease-in-out; }


// BG
@mixin bg-img {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center; }


// MAIN TRANSITION EFFECT FOR CSS ANIMATIONS
@mixin tm {
	@include ts(all $transition-time ease-out); }


// dots when text is longer than element
@mixin dots {
	display: block;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; }

@mixin mxa {
	margin-right: auto;
	margin-left: auto; }

// horizontally centered element
@mixin hc {
	display: block;
	margin-right: auto;
	margin-left: auto; }



@mixin t-shadow {
	text-shadow: 0px 0px 1px rgba(0, 0, 0, 1); }

@mixin t-shadow-2 {
	text-shadow: 0px 0px 1px #6b6b6b; }

@mixin t-shadow-3 {
	text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5); }


@mixin act {
	&:active,
	&.active {
		@content; } }

// all but not last
@mixin nl {
	&:not(:last-child) {
		@content; } }

// all but not first
@mixin nf {
	&:not(:first-child) {
		@content; } }

// this last child
@mixin l {
	&:last-child {
		@content; } }

// this first child
@mixin f {
	&:first-child {
		@content;
		@content; } }

// in this last item
@mixin li {
	*:last-child {
		@content; } }

// in this first item
@mixin fi {
	*:first-child {
		@content; } }

@mixin abs {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; }




// BREAKPOINTS

@mixin min1600 {
	@media print, screen and (min-width: 1600px) {
		@content; }
	/*min-width: 1600px END*/ }

@mixin min1200 {
	@media print, screen and (min-width: 1200px) {
		@content; }
	/*min-width: 1200px END*/ }

@mixin min1024 {
	@media print, screen and (min-width: 1024px) {
		@content; }
	/*min-width: 1024px END*/ }

@mixin min992 {
	@media print, screen and (min-width: 992px) {
		@content; }
	/*min-width: 992px END*/ }

@mixin min768 {
	@media print, screen and (min-width: 768px) {
		@content; }
	/*min-width: 768px END*/ }

@mixin min640 {
	@media print, screen and (min-width: 640px) {
		@content; }
	/*min-width: 768px END*/ }

@mixin min576 {
	@media print, screen and (min-width: 576px) {
		@content; }
	/*min-width: 768px END*/ }




@mixin max1199 {
	@media print, screen and (max-width: 1199px) {
		@content; }
	/*max-width: 1199pxEND*/ }

@mixin max1023 {
	@media print, screen and (max-width: 1023px) {
		@content; }
	/*max-width: 1199pxEND*/ }

@mixin max991 {
	@media print, screen and (max-width: 991px) {
		@content; }
	/*max-width: 991px END*/ }

@mixin max767 {
	@media print, screen and (max-width: 767px) {
		@content; }
	/*max-width: 767px END*/ }

@mixin max639 {
	@media print, screen and (max-width: 639px) {
		@content; }
	/*max-width: 639px END*/ }

@mixin max575 {
	@media print, screen and (max-width: 575px) {
		@content; }
	/*max-width: 575px END*/ }




@mixin min768-max991 {
	@media print, screen and (min-width: 768px) and (max-width: 991px) {
		@content; }
	/*(min-width: 768px) and (max-width: 991px) END*/ }

@mixin min640-max1023 {
	@media print, screen and (min-width: 640px) and (max-width: 1023px) {
		@content; }
	/*(min-width: 640px) and (max-width: 1023px) END*/ }




// selected text properties
@mixin sl($color) {
	::moz-selection {
		background: $color; }

	::selection {
		background: $color; } }

@mixin cl {
	&:before,
	&:after {
		@include ct;
		clear: both; } }

@mixin z($number) {
	@for $i from 1 through $number {
		.z#{$i} {
			z-index: $i; } } }

@mixin rem($font-size) {
	/*#{$font-size}px*/
	font-size: ($font-size/$font-size-base-custom) + rem; }

@mixin remXl($font-size) {
	/*#{$font-size}px*/
	font-size: ($font-size/$font-size-base-custom-xl) + rem; }

@mixin remPhone($font-size) {
	/*#{$font-size}px*/
	font-size: ($font-size/$font-size-base-mobile) + rem; }

@mixin radial($color1, $color2) {
	background-image: $color2;
	background-image: -webkit-radial-gradient($color1, $color2);
	background-image: radial-gradient($color1, $color2); }

@mixin keys($name) {
	@-webkit-keyframes #{$name} {
		@content; }

	@keyframes #{$name} {
		@content; } }

@mixin anim($value) {
	-webkit-animation: $value;
	animation: $value; }

@mixin ns {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; }

@mixin nw {
	white-space: nowrap; }

@mixin nwf {
	flex-flow: nowrap; }

@mixin an {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; }


// Foundation Columns
// =custom-columns($class, $quantity)
// 	@for $i from 1 through $quantity
// 		.#{$class}-#{$i}
// 			width: 100/($quantity/$i)+%


@mixin column-centered($class) {
	.#{$class}-centered,
	.#{$class}-centered:last-child:not(:first-child) {
		float: none;
		clear: both; }

	.#{$class}-centered {
		@include mxa; } }

@mixin column-uncentered($class) {
	.#{$class}-uncentered,
	.#{$class}-uncentered:last-child:not(:first-child) {
		position: static;
		float: left;
		margin-left: 0;
		margin-right: 0;
		clear: none; } }


// placeholder
@mixin ph($color) {
	input,
	textarea {
		&::-webkit-input-placeholder {
			color: $color; }
		&::-moz-placeholder {
			color: $color; }
		&:-ms-input-placeholder {
			color: $color; }
		&::placeholder {
			color: $color; } } }


// placeholder this
@mixin pht($color) {
	&::-webkit-input-placeholder {
		color: $color; }
	&::-moz-placeholder {
		color: $color; }
	&:-ms-input-placeholder {
		color: $color; }
	&::placeholder {
		color: $color; } }


// crossbrowser font mixin
@mixin font($file, $family, $weight, $style) {
	@font-face {
		font-family: $family;
		src: local($family), url("../fonts/" + $file) format("woff");
		font-weight: $weight;
		font-style: $style; } }

@mixin a {
	&:after {
		@content; } }

@mixin b {
	&:before {
		@content; } }

// =titles-color-inherit
// 	&
// 		h1, h2, h3, h4, h5, h6
// 			color: inherit

@mixin hd {
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		@content; } }


@mixin p($value) {
	padding: $value; }

@mixin px($value) {
	padding-right: $value;
	padding-left: $value; }

@mixin py($value) {
	padding-top: $value;
	padding-bottom: $value; }

@mixin m($value) {
	margin: $value; }

@mixin mx($value) {
	margin-right: $value;
	margin-left: $value; }

@mixin my($value) {
	margin-top: $value;
	margin-bottom: $value; }

// =outline
// 	&:focus
// 		outline: $border 1px dotted








