@import "variables";


// typography

html {
	font-size: $fontSizeBase +px; }

body {
	color: #313131;
	@include rem(16);
	background-color: #ffffff;
	&.no-scroll {
		margin: 0;
		height: 100%;
		overflow: hidden; } }

a {
	display: inline-block;
	color: inherit;
	@include trans-2;
	@include hfa {
		color: inherit;
		text-decoration: underline; }
	&:hover {
		color: $primary; }
	&.text-decoration-n {
		@include hfa {
			text-decoration: none; } } }



a:focus,
.btn:focus,
button:focus {
	outline: none !important; }

::selection {
	background-color: $secondary;
	color: #fff; }


small.smaller {
	font-size: 60% !important; }



/*HEADINGS*/
p {
	line-height: 24px; }

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
	margin-bottom: 8px; }

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	line-height: 1.5; }

h1, .h1 {
	@include rem(48); }

h2, .h2 {
	@include rem(36);
	&.bigger {
		@include rem(44); } }

h3, .h3 {
	@include rem(32);
	@include media-breakpoint-down(xs) {
		@include rem(34); }
	&.smaller {
		@include rem(28); } }

h4, .h4 {
	@include rem(24);
	&.smaller {
		@include rem(20); } }

h5, .h5 {
	@include rem(16);
	&.bigger {
		@include rem(18);
		@include media-breakpoint-down(xs) {
			@include rem(16); } } }

h6, ,h6 {
	@include rem(15); }


.fw-300 {
	font-weight: 300; }
.fw-400 {
	font-weight: 400; }
.fw-500 {
	font-weight: 500; }
.fw-600 {
	font-weight: 600; }
.fw-700 {
	font-weight: 700; }
.fw-800 {
	font-weight: 800; }
.fw-900 {
	font-weight: 900; }

b, strong {
	font-weight: 700; }

.lh-46px {
	line-height: 46px; }

.lh-16px {
	line-height: 16px; }

.line-height-1-19rem {
	line-height: 1.19rem; }

section {
	padding: 70px 0;
	position: relative;
	@include media-breakpoint-down(md) {
		padding: 50px 0; }
	&.padding-bigger {
		padding: 130px 0;
		@include media-breakpoint-down(md) {
			padding: 70px 0; }
		@include media-breakpoint-down(sm) {
			padding: 50px 0; } } }


.section-subtitle {
	color: rgba(57, 57, 57, 0.6);
	margin-bottom: 20px;
	font-weight: 700; }

.box-shadow-0 {
	box-shadow: none !important; }







.bg-white {
	background-color: #FFF !important; }

.bg-grey {
	background-color: #f8f8f8 !important; }

.bg-green {
	background-color: $success !important; }

.bg-pink {
	background-color: #ea5267 !important; }

.bg-blue {
	background-color: #2dabe7 !important; }

.text-white {
	color: #fff !important; }

.text-green {
	color: $success !important; }

.text-yellow {
	color: #f4e404 !important; }

.text-pink {
	color: #ea5267 !important; }

.text-blue {
	color: #2dabe7 !important; }

.text-dark-blue {
	color: #05355c !important; }

.text-shadow {
	text-shadow: 0px 0px 1px #6b6b6b; }



.circle-img {
	background-color: #FFF;
	width: 160px;
	height: 160px;
	border-radius: 50%;
	padding-top: 14px;
	img {
		display: block;
		max-width: 134px;
		width: 100%;
		border-radius: 50%;
		margin: 0 auto; } }



@media (min-width: 1200px) {
	.container {
		max-width: 1170px; }

	.container.smaller {
		max-width: 850px; } }




.gotop-btn {
	position: fixed;
	right: 0px;
	bottom: 50px;
	z-index: -2;
	padding: 12px 12px;
	background-color: rgba(255,255,255,0.8);
	opacity: 0;
	border-radius: 50%;
	display: inline-block;
	@include trans;
	box-shadow: 4px 7px 18px 0px rgba(0, 137, 188, 0.2);
	transform: scale(0.8);
	&:hover {
		box-shadow: 4px 7px 18px 0px rgba(0, 137, 188, 0.4);
		background-color: rgba(255,255,255,1);
		transform: scale(1); }
	.fa {
		position: relative;
		top: -2px; }
	&.visible {
		right: 50px;
		opacity: 1;
		z-index: 995; }

	.arr-top-icon {
		width: 100%;
		max-width: 28px;
		position: relative; } }



// Extra small
// <576px	Small
// ≥576px	Medium
// ≥768px	Large
// ≥992px	Extra large
// ≥1200px

// breakpoint-only
// @include media-breakpoint-only(xs)
// @include media-breakpoint-only(sm)
// @include media-breakpoint-only(md)
// @include media-breakpoint-only(lg)
// @include media-breakpoint-only(xl)

// max-width
// @include media-breakpoint-down(xs)
// @include media-breakpoint-down(sm)
// @include media-breakpoint-down(md)
// @include media-breakpoint-down(lg)

// min-width
// @include media-breakpoint-up(sm)
// @include media-breakpoint-up(md)
// @include media-breakpoint-up(lg)
// @include media-breakpoint-up(xl)
