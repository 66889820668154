// Colors
$primary: #0089bc;
$secondary: #2c4076;
// $success: #2fd62d
// $danger: #ea5267
// $warning: #ffbd00
// $info: #5DA0B0
// $light: #e9e5d4
$dark: #282828;

// $text-muted: #b3b3b3



// main text color
$main: #282828;
$body-color: #282828;

$body-bg: #fff;


// Fonts
$font-size-base-custom: 16;
$font-size-base: 16;
$font-size-base-mobile: 14;

// Font Families
$font-family-base: 'Roboto', sans-serif;
$font-main: $font-family-base;
$font-secondary: $font-family-base;
$font-condensed: $font-family-base;

@mixin fm {
	font-family: $font-main; }


// // headings
// $h1-font-size: 60px
// $h2-font-size: 36px
// $h3-font-size: 30px
// $h4-font-size: 24px
// $h5-font-size: 18px
// $h6-font-size: 16px

// $h1-font-size-custom: 60
// $h2-font-size-custom: 40
// $h3-font-size-custom: 30
// $h4-font-size-custom: 24
// $h5-font-size-custom: 18
// $h6-font-size-custom: 16



// Animations Time
$transition-time: 0.2s;



