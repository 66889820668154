.media-card {
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  min-height: 290px;
  @include trans;
  @include media-breakpoint-down(lg) {
    min-height: auto; }
  img {
    width: 100%;
    max-width: 140px;
    margin-right: 30px;
    @include trans;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      margin-right: 0; } }
  &:hover {
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35); }
  &:hover img {
    transform: scale(1.05); } }
