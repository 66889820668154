.call-action-section {
	background-color: $dark;
	&--title {
		font-weight: 900;
		color: #ffffff;
		margin-bottom: 30px;
		line-height: 30px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 16px; }
		@include media-breakpoint-down(xs) {
			br {
				display: none; } } }
	&--subtitle {
		font-weight: 300;
		margin-bottom: 0;
		color: #868383;
		line-height: 24px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px; } } }







