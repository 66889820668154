
body {
	background-color: $body-bg; }



.color-primary,
.color--primary {
	color: $primary; }


.color-secondary {
	color: $secondary; }

.color-success {
	color: $success; }

.color-warning {
	color: $warning; }


.h-100 {
	height: 100%; }











