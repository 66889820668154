.btn-icon-phone-animation {
  i {
    @include trans; }
  &:hover i {
    animation-name: shake;
    animation-duration: 0.02s;
    animation-delay: 0.1s;
    animation-iteration-count: infinite;
    filter: blur(0.3px); } }

@keyframes shake {
  0% {
    transform: translateX(0) scale(1.2); }
  50% {
    transform: translateX(-1) scale(1.2); }
  100% {
    transform: translateX(2px) scale(1.2); } }



.btn-icon-whatsapp-animation {
  i {
    @include trans; }
  &:hover i {
    animation-name: buble;
    animation-duration: 1.2s;
    animation-iteration-count: infinite; } }

@keyframes buble {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }




@keyframes presentation {
  0% {
    transform: rotateY(0deg) scale(1); }
  50% {
    transform: rotateY(180deg) scale(1.5); }
  100% {
    transform: rotateY(0deg) scale(1); } }
