.title-wordbg {
  position: relative;
  z-index: 0;
  display: inline-block;
  text-align: center;
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%; }
  &--word {
    font-weight: 900;
    @include rem(400);
    text-transform: uppercase;
    line-height: 300px;
    position: relative;
    z-index: 2;
    color: #f5f7fa;
    @include media-breakpoint-down(md) {
      @include rem(330); }
    @include media-breakpoint-down(sm) {
      line-height: 130px;
      @include rem(260); } }
  &--text {
    z-index: 3;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: inline-block;
    margin: 0;
    white-space: nowrap;
    @include media-breakpoint-down(md) {
      white-space: normal;
      line-height: 1;
      width: 100%; } } }
