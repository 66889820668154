

// home-sec
.home-sec {
	padding: 0; }

.home-blind {
	position: relative;
	padding-top: 250px;
	padding-bottom: 350px;
	background-color: rgba(0,0,0,0.5);
	z-index: 3;
	@include media-breakpoint-down(lg) {
		padding-top: 200px;
		padding-bottom: 100px; }
	@include media-breakpoint-down(sm) {
		padding-bottom: 80px; } }

.home-content {
	position: relative;
	z-index: 1;
	text-align: center;
	&-logo {
		width: 100%;
		max-width: 690px;
		margin-bottom: 40px;
		@include media-breakpoint-down(md) {
			max-width: 500px; }
		@include media-breakpoint-down(sm) {
			max-width: 350px; } } }

.home-title {
	margin-bottom: 0px;
	color: #fff;
	font-weight: 300;
	letter-spacing: 1.2px;
	position: relative;
	z-index: 3;
	@include t-shadow;
	@include rem(24);
	br {
		@include media-breakpoint-down(sm) {
			display: none; } } }


.home-banner {
	display: inline-block;
	position: absolute;
	right: 80px;
	bottom: -70px;
	z-index: 2;
	img {
		width: 100%;
		max-width: 300px;
		@include trans; }
	&:hover {
		img {
			transform: scale(1.05); } } }



// end home-sec








