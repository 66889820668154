.services-call-action {
  padding: 100px 0;
  background-image: url(../img/service-callaction-image-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 center;
  @include media-breakpoint-down(md) {
    padding: 40px 0; }
  &--box {
    background-color: $secondary;
    padding: 80px 15px;
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      padding: 55px 15px; }
    @include media-breakpoint-down(xs) {
      padding: 25px 15px 40px; } }
  &--title {
    line-height: 28px;
    br {
      @include media-breakpoint-down(md) {
        display: none; } } }
  &--btn {
    box-shadow: none;
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%); } }
