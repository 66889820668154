
.btn {
  font-weight: 300;
  border-radius: 0;
  padding: 10px 15px;
  box-shadow: 0px 5px 21px #000; }

.btn-primary:hover,
.btn-primary:focus, .btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  background-color: $secondary;
  border-color: $secondary;
  box-shadow: none;
  box-shadow: 0px 5px 21px rgba(0,0,0,0.3); }

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: $secondary;
  border-color: $secondary;
  box-shadow: none;
  box-shadow: 0px 5px 21px rgba(0,0,0,0.3); }





